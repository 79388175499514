.Reasons{
    padding: 0 2rem;
    display: flex;
    grid: 2rem;
}
.left-r>{
   flex: 1 1;
   display: grid;
   grid-template-columns: repeat(3,1fr);
   gap: 1rem;
   grid-auto-rows: 1fr;
 
}
.left-r>img{
   object-fit: cover;
   
 }
 .left-r>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
   
 }
 .left-r>:nth-child(2){
    width: 24rem;
    position: absolute;
    left:15%;
    grid-row: 2/3;
    grid-column: 2/4;
    height: 17rem;
 }
 .left-r>:nth-child(3){
    position: relative;
    width: 12rem;
    grid-column: 2/3;
    grid-row: 2;
    left:1%;
 }
 .left-r>:nth-child(4){
    position: relative;
    width: 12.5rem;
    grid-row: 2;
    grid-column: 3/4;
    left:2%;
    height: 9.5rem;
 }
.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;}
.right-r>span{
    margin:10px;
    font-weight: bold;
    color: var(--orange);}
  .gg{
    margin:12px;
  }
.rigth-r>div{

    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    font-weight: bold;
    color: white;
    display:flex;
    flex-direction: column;
    gap: 2rem;}
.details-r>div{
    display: flex;
    font-size:1rem;
    gap: 1rem;
}
.details-r>div>img{
  width: 2rem;
  height: 2rem;
}
.partners{
  display: flex;
  gap: 1rem;
  margin:12px;
}
.partners>img{
  width: 2.5rem;
}
@media screen and (max-width : 768px) {
.Reasons{
  flex-direction: column;
}
.left-r{/*espace ecran et image */
  grid-auto-rows: auto;
  overflow: hidden;
}
.left-r>:nth-child(1){
  width: 7rem;
  height: 17rem;
}
.left-r>:nth-child(2){
  width: 15rem;
  height: 10rem;
}
.left-r>:nth-child(3){
  width: 7rem;

}
.left-r>:nth-child(4){
  width: 7rem;
  height: 6rem;
}
}