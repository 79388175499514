.Footer-container{
    position: relative;
    
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);

}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.blur-f-1{ /*ithel il7amar mn louta */
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red ;

}
.blur-f-2{ /*ithel il orange mn louta */
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0) ;

}