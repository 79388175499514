.hero{
    display: flex;
    justify-content: space-between;
 
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex:3;
    display: flex;
    gap:2rem;
    flex-direction: column;
}
.rigth-h{
    flex:1;
    position: relative;/* acune effet*/
    background-color: orange;
}
.thebestad{
    margin-top: 4rem;
    background-color: rgba(255, 235, 205, 0.166);
  border-radius:4rem ;
  width:fit-content;/* 3la gad ilkitiba bithabat*/
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.thebestad>div{
 position: absolute;
    background-color: orange;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index:-1;/* arriere plan(background) sig -1 */

}
.hero-text{
    display:flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold; /* bl 5at il3arith */
    color: white;
    text-overflow: inherit;

}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
   font-weight: 200;
   letter-spacing: 1px;
   width: 80%;
   text-transform: none;
}
.figures{
    display :flex;/*our que 3 grans span sera meme ligne*/
    gap :2rem;/* distance entre 2 ligne*/
}
.figures>div{
    display: flex; /* span et dispaly flex donc te7t ba3thhom */ 
    flex-direction: column; 
}
.figures>div>span:nth-of-type(1){/*chaque span 1ere partie*/
    color: white;
    font-size: 2rem;/*taille d'ecriture*/
}
.figures>div>span:nth-of-type(2){/*chaque span 1ere partie*/
    color: gray;
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap:1rem;/*espace entre 2 butons*/
    font-weight: normal;}
.hero-buttons>:nth-of-type(1){
    color :white;
    background-color:orange ;
    width: 8erm;
}
.hero-buttons>:nth-child(2){
    color :white;
    background-color:transparent ;
    width: 8erm;
    border: 2px solid orange;
}
.rigth-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;

}
.heart-rate{
    display: flex;
    flex-direction: column;/* verticalemment si non horizantale */
    gap:1rem;
    background-color: darkGrey;
    width: fit-content;
    padding: 1rem;
    align-items: start;
    background-repeat: 5rem;
    position: absolute;
    right: 4rem;
 top: 7rem;

}
.heart-rate>:nth-child(1){
    width: 2rem;
}
.heart-rate>:nth-child(2){color: gray;
} /* ou on :  .heart-rate>span:nth-of-type(1){
    color: gray;

} */
.heart-rate>:nth-child(3){color: white;
    font-size:1.5rem;
    }
.hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;/* 3 ligne pour palcer l'image dans sa position */
    width: 23rem;
}
.hero-image-back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;

}
.calories{
    display: flex;
    grid-template: 2rem;
    background-color: var(--caloryCard);/*var pour liee *//*caloryCard existe dans app.css*/
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 32rem;
    right: 28rem;
}
.calories>img{
    width: 3rem;

}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color :var(--gray)
}
.calories>div>:nth-child(2){
    color :white;
    font-size: 1.5rem;
}
.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;}
 @media screen and (max-width : 768px) { /*(768 pour large iphone 8)pour regle avec application mobile */
    /*il faut and entre 2 espace w espace avant 768 */ 
    .hero{
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .thebestad{
        margin-top: 0;
        font-size: small;
        align-self: center;
    transform: scale(0.8);}
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;

    }
    .hero-buttons{
        justify-content: center;
    }
    .figures>div>span:nth-of-type(1){ /*140 w 980 w 50 */
    font-size: large;
    
    }
    .figures>div>span:nth-of-type(2){ 
    font-size: small;

    }
    .rigth-h{
        position: relative;
        background: none;}
    .heart-rate{
        left: 1rem;
        top: 2rem;
    }
    .calories{
        left: 5rem;
        top: 2rem;
        position: relative;

    }
    .calories>img{
        width: 2rem;
    }
    .calories>div>:nth-child(2){
        color: white;
        font-size: 1rem;}
    .hero-image{
            position: relative;
            width: 15rem;
            left: 7rem;
            top: 4rem;
            align-self: center;
        }
    .hero-image-back{
        width: 15rem;
        left: 2rem;
        top: 0rem;

    }    


    
 }