.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem ;
}
.header-menu{
    list-style: none; /* on a une list  "ul" pour eviter le point de debit de chaque ligne de list*/
   display: flex;
   gap:2rem;
   color : white;
}

.header-menu>li:hover{/* hover pour donne un valeur lorsque souris pass */
    cursor: pointer;/*pour que sur remarquable si sourit passe*/
    color:orange;
}
@media screen and (max-width : 768px) {
 .header>:nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
 }
 .header-menu{ /*fixe homa w program ww... testimonals*/
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
 }


}